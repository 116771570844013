.closed {
  display: none;
}

.overlay {
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 9999;
  background: hsla(0,0,0,.5);
  justify-content: center;
  align-items: center;
}

.modal {
  width: 100%;
  max-height: 100%;
  overflow-y: auto;
  background: darken(white, 10%);
  border: 1px solid black;
}

@media (min-width: 550px) and (min-height:600px){
  .modal {
    width: 550px;
    max-height: 600px;
  }
}

.content {
  display: flex;
  align-items: center;
  height: 100%;
  width: 100%;
  margin: 0 auto;
  // padding: 10px;
}

@media (max-width: 575px){
  .content {
    width: 80%;
  }
}

.close {
  position: fixed;
  top: 0;
  right: 0;
  height: 2rem;
  width: 2rem;
  border: 1px solid gray;
  background: white;
  z-index: 55;
  cursor: pointer;

  &:before, &:after {
    content: '';
    position: absolute;
    top: .9rem;
    right: -.15rem;
    width: 2rem;
    height: 0.1rem;
    background: #888;
    z-index: 56;
  }

  &:before {
    transform: rotate(45deg);
  }

  &:after {
    transform: rotate(-45deg);
  }
}