// 575
.item {
  margin: 5px;
  flex: 1 0 calc(55% - 20px);
  display: flex;
  flex-direction: column;
  flex-flow: wrap;
  height: 6rem;
  padding-left: 7.5%;
  padding-right: 7.5%;
  div {
    width: 100%;
    height: 25%;
    background: lighten(gray, 20%);
  }
  div:nth-child(2n){
    background: lighten(black, 20%);
    color: white;
  }
}

@media (min-width: 575px){
  .item {
    flex: 1 0 calc(50% - 20px);
    padding-left: 0;
    padding-right: 0;
  }
}

@media (min-width: 780px){
  .item {
    flex: 1 0 calc(33.333% - 20px);
  }
}