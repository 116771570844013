.material {
  margin: 5px;
  div {
    width: 100%;
  }
  div:first-of-type{
    background: lighten(black, 25%);
    color: white;
  }
}

@media (max-width: 575px){
  .material {
    width: 100%;
  }
}

@media (min-width: 575px){
  .material {
    width: calc(50% - 10px);
  }
}