.materials {
  display: flex;
  flex-flow: wrap;
  margin: 0 auto;
  justify-content: space-around;
  margin-bottom: 5px;
}

.tip {
  font-style: italic;
  width: calc(100% - 10px);
  border-top: 1px solid black;
  margin-top: 3px;
}

@media (max-width: 575px){
  .tip {
    width: 100%;
  }
}